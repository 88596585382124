var PhoneSnom_hd35x = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    // Initiales setzen, eigene Nebenstelle, Tasten mit BLF-Verschiebung
    funcs.set_initial_data(pdata.own_exten, {});

    // Funktionstasten in Ebenen
    for (var i = 1; i <= 16; i++) {
      $('#pkey'+i).droppable({
        accept: '.key',
        hoverClass: 'highlight-target',
        drop: function(event, ui) {
          if ( ui.draggable.attr('id') == "f_direct" ) {
            funcs.set_direct($(this), false);
          } else if ( ui.draggable.attr('id') == "f_nconfig" || ui.draggable.attr('id') == "f_noop" ) {
            funcs.set_keyfunc($(this), $(ui.draggable), false);
          } else {
            funcs.set_keyfunc($(this), $(ui.draggable), true);
          }
        }
      });
    }

    $('#dialplan').on('change', function() {
      funcs.update_value('dialplan', $('#dialplan').val());
    })

    $('#sel_dialplan').on('change', function() {
      funcs.change_dialplan($(this).val());
    });

  };

  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };
})(PhoneSnom_hd35x || {});
window.PhoneSnom_hd35x = PhoneSnom_hd35x;
