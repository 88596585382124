import { url_get_id } from './iptam.js';

var PhoneExtmodules = (function(modul) {

  var type2module = {
    'm680i': 'M680i', 'm685i': 'M685i', 'km_27': 'KM 27', 'km_38': 'KM 38', 'kd_39': 'KD 39', 'exp38': 'EXP38', 'exp39': 'EXP39', 'exp40': 'EXP40', 'exp43': 'EXP43', 'exp50': 'EXP50', 'snomEXPmod2': 'Erweiterungsmodul V2', 'zy700': 'ZY 700 PRO', 'zy900': 'ZY 900 PRO', 'd3': 'D3', 'd7': 'D7', 'd7c': 'D7c', 'exp_max': 'Erweiterungsmodul'
  };

  function get_module_name(type) {
    var name = type2module[type];
    return name;
  };

  return {
    get_module_name: get_module_name,
  };

})(PhoneExtmodules || {});
window.PhoneExtmodules = PhoneExtmodules;

jQuery(document).ready(function() {
  if ( $('div[id=phone-js-data]').length ) {
    console.log("Telefon");
    var phone_data = {
      vendor: $('div[id=phone-js-data]').data('vendor'),
      model: $('div[id=phone-js-data]').data('model'),
      type: $('div[id=phone-js-data]').data('type-id'),
      own_exten: $('div[id=phone-js-data]').data('ownextension'),
    }
    console.log({phone_data});
    var phone;
    switch ( phone_data.type ) {
      case 1:
        phone = PhoneMitel_6751;
        break;
      case 2:
        phone = PhoneMitel_6753;
        break;
      case 3:
        phone = PhoneMitel_6755;
        break;
      case 4:
        phone = PhoneMitel_6757;
        break;
      case 5:
        phone = PhoneSnom_300;
        break;
      case 6:
        phone = PhoneSnom_3xx;    // Snom 320
        break;
      case 7:
        phone = PhoneSnom_3xx;    // Snom 360
        break;
      case 8:
        phone = PhoneSnom_3xx;    // Snom 370
        break;
      case 9:
        phone = undefined;
        break;
      case 10:
        phone = PhoneMitel_6730;
        break;
      case 11:
        phone = PhoneMitel_6730;  // Mitel 6731i
        break;
      case 12:
        phone = PhoneMitel_6739;
        break;
      case 13:
        phone = PhoneSnom_82x;
        break;
      case 14:
        phone = PhoneSnom_82x;
        break;
      case 15:
        phone = PhoneSnom_870;
        break;
      case 16:
        phone = undefined;
        break;
      case 17:
        phone = PhoneYealink_t28;   // Tiptel 286
        break;
      case 18:
        phone = PhoneYealink_t26;   // Tiptel 284
        break;
      case 19:
        phone = PhoneYealink_t20;   // Tiptel 280
        break;
      case 21:
        phone = PhoneYealink_t28;   // Yealink T28
        break;
      case 22:
        phone = PhoneYealink_t26;   // Yealink T26
        break;
      case 23:
        phone = PhoneYealink_t22;   // Yealink T22
        break;
      case 24:
        phone = PhoneYealink_t20;   // Yealink T20
        break;
      case 26:
        phone = PhoneYealink_t22;   // Tiptel 282
        break;
      case 27:
        phone = PhoneSnom_72x;      // Snom 720
        break;
      case 28:
        phone = PhoneSnom_76x;      // Snom 760
        break;
      case 29:
        phone = PhoneMitel_6755;    // Mitel 6735i
        break;
      case 30:
        phone = PhoneMitel_6757;    // Mitel 6737i
        break;
      case 31:
        phone = PhoneSnom_71x;      // Snom 710
        break;
      case 32:
        phone = PhoneGigaset_de310;
        break;
      case 33:
        phone = PhoneGigaset_de410;
        break;
      case 34:
        phone = PhoneGigaset_de700;
        break;
      case 35:
        phone = PhoneGigaset_de700;   // DE900
        break;
      case 36:
        phone = PhoneGigaset_n510;
        break;
      case 37:
        phone = PhoneGigaset_n720;
        break;
      case 38:
        phone = PhoneYealink_t32;
        break;
      case 39:
        phone = PhoneYealink_t38;
        break;
      case 40:
        phone = PhoneTiptel_t3110;
        break;
      case 41:
        phone = PhoneTiptel_t3120;
        break;
      case 42:
        phone = PhoneTiptel_t3130;
        break;
      case 43:
        phone = PhoneSnom_71x;    // 715
        break;
      case 44:
        phone = PhoneYealink_t41; // T41
        break;
      case 45:
        phone = PhoneYealink_t41; // T42
        break;
      case 46:
        phone = PhoneYealink_t46; // T46
        break;
      case 47:
        phone = PhoneYealink_t48; // T48
        break;
      case 48:
        phone = PhoneSnom_72x;    // D725
        break;
      case 49:
        phone = PhoneSnom_76x;    // D765
        break;
      case 50:
        phone = PhoneSnom_3xx;    // Snom D375
        break;
      case 51:
        phone = PhoneMitel_6867;
        break;
      case 52:
        phone = PhoneMitel_6869;
        break;
      case 53:
        phone = PhoneSnom_m300;
        break;
      case 54:
        phone = PhoneSnom_m700;
        break;
      case 55:
        phone = PhoneUnify_os15;
        break;
      case 56:
        phone = PhoneUnify_os40;
        break;
      case 57:
        phone = PhoneSnom_d305;
        break;
      case 58:
        phone = PhoneSnom_d305;   // Snom D315
        break;
      case 59:
        phone = PhoneSnom_d345;
        break;
      case 60:
        phone = PhoneSnom_d745;
        break;
      case 61:
        phone = PhoneGigaset_max_10;
        break;
      case 62:
        phone = PhoneGigaset_max_basic;
        break;
      case 63:
        phone = PhoneGigaset_max_3;
        break;
      case 64:
        phone = PhoneYealink_t40;
        break;
      case 65:
        phone = PhoneMitel_6865;
        break;
      case 66:
        phone = PhoneSnom_71x;    // Snom D712
        break;
      case 67:
        phone = PhoneSnom_c520;
        break;
      case 68:
        phone = PhoneSnom_d785;
        break;
      case 69:
        phone = PhoneSnom_d120;
        break;
      case 70:
        phone = PhoneSnom_m200;
        break;
      case 71:
        phone = PhoneSnom_d345; // Snom D385
        break;
      case 72:
        phone = PhoneGigaset_max_3;
        break;
      case 73:
        phone = PhoneSnom_d735;
        break;
      case 74:
        phone = PhoneSnom_d717;
        break;
      case 75:
        phone = PhoneGigaset_n870;
        break;
      case 76:
        phone = PhoneGigaset_n870;  // Gigaset N670
        break;
      case 77:
        phone = PhoneSnom_d735;     // Snom D335
        break;
      case 78:
        phone = PhoneYealink_t53;   // T53
        break;
      case 79:
        phone = PhoneYealink_t54;   // T54
        break;
      case 80:
        phone = PhoneYealink_t58;   // T58
        break;
      case 81:
        phone = PhoneYealink_t57;   // T57
        break;
      case 82:
        phone = PhoneSnom_m700;     // Snom M900
        break;
      case 83:
        phone = PhoneSnom_d713;
        break;
      case 84:
        phone = PhoneSnom_m100;
        break;
      case 85:
        phone = PhoneSnom_m700;     // Snom M400
        break;
      case 86:
        phone = PhoneGrandstream_wp820;
        break;
      case 87:
        phone = PhoneGrandstream_ht80x;
        break;
      case 88:
        phone = PhoneGrandstream_ht80x;
        break;
      case 89:
        phone = PhoneGrandstream_wp820;   // Grandstream WP810
        break;
      case 90:
        phone = PhoneGrandstream_wp820;   // Grandstream WP822
        break;
      case 91:
        phone = PhoneSnom_d862;
        break;
      case 92:
        phone = PhoneSnom_d865;
        break;
      case 93:
        phone = PhoneGigaset_n870;  // Gigaset N610
        break;
      case 94:
        phone = PhoneSnom_d140;     // D140
        break;
      case 95:
        phone = PhoneSnom_d140;     // D150
        break;
      case 96:
        phone = PhoneSnom_hd10x;    // HD100
        break;
      case 97:
        phone = PhoneSnom_hd10x;    // HD101
        break;
      case 98:
        phone = PhoneSnom_hd35x;    // HD350W
        break;
      case 99:
        phone = PhoneSnom_hd35x;    // HD351W
        break;
      case 100:
        phone = PhoneSnom_pa1;      // PA1, PA1+
        break;
      case 101:
        phone = PhoneSnom_sp800;
        break;
      case 102:
        phone = PhoneSnom_c620;
        break;
      case 103:
        phone = PhoneMitel_6873;
        break;
    }
    $('.key').draggable({opacity: 0.7, helper: 'clone'});
    var funcs = PhoneFunctions;
    if ( phone ) {
      phone.init(phone_data, funcs);
      $('button[name=do_submit]').on('click', function() {
        phone.do_submit(funcs);
      });
    } else {
      var form;
      if ( $('form#phonetemplate_form').length ) {
        $('button[name=do_submit]').on('click', function() {
          form = $('form#phonetemplate_form');
          form.submit();
        });
      }
    }
  }

  if ( $('div[id=extmod-js-data]').length ) {
    console.log("Erweiterungsmodul");
    var extmod_data = {
      model: $('div[id=extmod-js-data]').data('model'),
      own_exten: $('div[id=extmod-js-data]').data('ownextension'),
      key_count: $('div[id=extmod-js-data]').data('keycount'),
      mod_count: $('div[id=extmod-js-data]').data('modcount'),
    }
    console.log({extmod_data});
    var extmod;
    if ( extmod_data.model.length ) {
      switch ( extmod_data.model ) {
        case "d3":
          extmod = ExtMod_snom_d7;
          break;
        case "d7":
          extmod = ExtMod_snom_d7;
          break;
        case "d7c":
          extmod = ExtMod_snom_d7c;
          break;
        case "snomEXPmod2":
          extmod = ExtMod_snomEXPmod2;
          break;
        case "m680i":
          extmod = ExtMod_mitel_m680;
          break;
        case "m685i":
          extmod = ExtMod_mitel_m685;
          break;
        case "zy700":
          extmod = ExtMod_gigaset_z700;
          break;
        case "zy900":
          extmod = ExtMod_gigaset_z700;
          break;
        case "exp_max":
          extmod = ExtMod_gigaset_exp_max;
          break;
        case "km_27":
          extmod = ExtMod_tiptel_km27;
          break;
        case "km_38":
          extmod = ExtMod_yealink_exp38;
          break;
        case "kd_39":
          extmod = ExtMod_yealink_exp39;
          break;
        case "exp38":
          extmod = ExtMod_yealink_exp38;
          break;
        case "exp39":
          extmod = ExtMod_yealink_exp39;
          break;
        case "exp40":
          extmod = ExtMod_yealink_exp40;
          break;
        case "exp43":
          extmod = ExtMod_yealink_exp43;
          break;
        case "exp50":
          extmod = ExtMod_yealink_exp50;
          break;
      }
      extmod_data['keys_per_mod'] = extmod_data.key_count / extmod_data.mod_count;
      $('.key').draggable({opacity: 0.7, helper: 'clone'});
      var funcs = PhoneFunctions
      extmod.init(extmod_data, funcs);
      $('button[name=do_submit]').on('click', function() {
        extmod.do_submit(funcs);
      });
    } else {
      // Kein Erweiterungsmodul aktiv
      $('button[name=do_submit]').on('click', function() {
        $('#extension_mod_form').submit();
      });
    }
  }

  if ( $('div[id=phone-js-data]').length || $('div[id=extmod-js-data]').length ) {
    $('.modal-dialog-centered').keypress(function(e) {
      if ( e.which == 13) {
        $(this).find('#save-button').click();
        return false;
      }
    });
  }

  if ( $('button[name=set_passwords]').length ) {
    $('button[name=set_passwords]').on('click', function() {
      $('#password-form').modal();
    });

    // Speichern der Passwörter
    $('#password-form #save-button').on('click', function() {
      var id = url_get_id('form[action^="/admin/phoneoverview/"]', -2);
      console.log("ID " + id);
      var vals = {};
      vals['admin_pw'] = $('#password-form #admin_pw').val();
      vals['admin_pw_conf'] = $('#password-form #admin_pw').val();
      vals['user_pw'] = $('#password-form #user_pw').val();
      vals['user_pw_conf'] = $('#password-form #user_pw').val();
      $.post('/admin/phoneoverview/' + id + '/update_pw', vals, function(data, status) {
        console.log(data);
        if ( data.error == false )
          $('#password-form').modal('hide');
      });
    });
  }

});
