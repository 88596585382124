var PhoneSnom_pa1 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data("", {});
    var config = funcs.get_configuration();

    const keys1 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "P1", "P2", "P3", "P4"];
    keys1.forEach(function(k) {
      var id = "out_ident_" + k;
      $("#"+id + " option[value='" + config[id] + "']").prop('selected', true);
      id = "dest_" + k;
      $("#"+id).val(config[id]);
    });
    const keys2 = [1, 2, 3, 4];
    keys2.forEach(function(k) {
      var id = "default_" + k;
      $('#'+id).prop('checked', config[id]);
      id = "off_delay_" + k;
      $("#"+id).val(config[id]);
      id = "action_on_" + k;
      $("#"+id + " option[value='" + config[id] + "']").prop('selected', true);
      id = "action_off_" + k;
      $("#"+id + " option[value='" + config[id] + "']").prop('selected', true);
    });
  };

  function do_submit(funcs) {
    console.log("Submit");
    const keys1 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "P1", "P2", "P3", "P4"];
    keys1.forEach(function(k) {
      var id = "dest_" + k;
      funcs.update_value(id, $('#'+id).val());
      id = "out_ident_" + k;
      funcs.update_value(id, $('#'+id + ' option:selected').val()); 
    });

    const keys2 = [1, 2, 3, 4];
    keys2.forEach(function(k) {
      var id = "default_" + k;
      funcs.update_value(id, $('#'+id).prop('checked'));
      id = "off_delay_" + k;
      funcs.update_value(id, $('#'+id).val());
      id = "action_on_" + k;
      funcs.update_value(id, $('#'+id + ' option:selected').val()); 
      id = "action_off_" + k;
      funcs.update_value(id, $('#'+id + ' option:selected').val()); 
    });

    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };

})(PhoneSnom_pa1 || {});
window.PhoneSnom_pa1 = PhoneSnom_pa1;
