import { activate_tables, start_spinner_popup, stop_spinner_popup, url_get_id } from '../iptam.js';

var waitingqueues_set_event_handler;

jQuery(document).ready(function() {

  if ( $('#waitingqueue_title').length ) {
    /* Übersicht Warteschlange */
    var table = activate_tables('#waitingqueues-table', false, [-1]);

    table.on( 'draw', function () {
      $('.alert').remove();
      $('.delete_entry').on('click', function() {
        delete_entry($(this));
      });
    });
    
    $('.delete_entry').on('click', function() {
      delete_entry($(this));
    });
    
    // Löschen einer Telefonschablone
    var entry_id;
    function delete_entry(entry) {
      entry.tooltip('hide');
      entry_id = entry.data('entry-id');
      start_spinner_popup();
      $.get('/admin/waitingqueues/' + entry_id + '/get_data4delete', function(data,  status) {
        stop_spinner_popup();
        if ( data['error'] ) {
          $('.iptam-main').prepend("<div class='alert alert-danger' role='alert'>" + data['error'] + "</div>");
        } else {
          $('span[id=name]').html(data['name']);
          if ( data['user_count'] > 0 ) {
            $('#multiple_members').show();
            $('#no_members').hide();
            var list = "";
            for (var i=0; i < data['user_count']; i++ ) {
              list += data['user_list'][i][0] + " [" + data['user_list'][i][1] + "]<br />";
            }
            $('div[id=user_list]').html(list);
          } else {
            $('#no_members').show();
            $('#multiple_members').hide();
          }
          $('#waitingqueue_destroy').modal();
        }
      });
    }

    /* Löschen tatsächlich ausführen */
    $('#delete-button').on('click', function() {
      $('#delete-form').attr('action', '/admin/waitingqueues/' + entry_id);
      $('#delete-form').submit();
    });

  }

  if ( $('#waitingqueue_edit_title').length ) {

    /* Auf der "Bearbeiten"-Seite */

    var type_of_action;
    var prompt_name;
    
    /* Autofocus beim Erscheinen der Dialog-Box */
    $('#select_phone').on('shown.bs.modal', function() {
      if ( $('#phone_own').length == 0 ) {
        $(this).find('#number').focus();
      }
    });

    $('#select_phone').keypress(function(e) {
      if ( e.which == 13) {
        $(this).find('#save-button').click();
        return false;
      }
    });

    waitingqueues_set_event_handler = function() {

      /* Aufnehmen von Ansagen via Telefon */
      $('button[name$=rec]').on('click', function(e) {
        type_of_action = 'record';
        prompt_name = this.name;
        $('#select_phone').modal();
      });

       /* Abspielen von Ansagen via Telefon */
      $('button[name$=play]').on('click', function(e) {
        type_of_action = 'play';
        prompt_name = this.name;
        $('#select_phone').modal();
      });

      /* Hochladen von Audio-Dateien */
      $('button[name=upload1]').on('click', function(e) {
        set_upload($(this).data('title'), 'greetload', $(this).data('file-type'));
      });
      $('button[name=upload2]').on('click', function(e) {
        set_upload($(this).data('title'), 'byeload', $(this).data('file-type'));
      });

      /* Löschen von Ansagen */
      $('button[name$=del]').on('click', function(e) {
        var id = url_get_id('form[action^="/admin/waitingqueues/"]');
        var vals = get_current_vals();
        vals['what'] = 'delete';
        vals['type'] = this.name;
        $.post("/admin/waitingqueues/" + id + "/ajax_update", vals, function(data, status) {
        });
      });

      $('#select_phone #number').click(function(e) {
        $('#phone_number').prop("checked", true);
      });

      $('input[type=radio][name=phone]').on('change', function() {
        if ( $(this).val() != 'number' )
          $('#number').val("");
      });

      /* Speichern Telefonauswahl und Aufnahme / Abspielen ausführen */
      $('#select_phone #save-button').on('click', function() {
        var phone = $('input[type=radio][name=prompt]:checked').val();
        var number = $('#number').val();
        var id = url_get_id('form[action^="/admin/waitingqueues/"]');
        var vals = get_current_vals();
        vals['what'] = type_of_action;
        vals['type'] = prompt_name;
        vals['phone'] = (phone == 'own' || !number) ? 'own' : number;
        $('#select_phone').modal('hide');
        $.post("/admin/waitingqueues/" + id + "/ajax_update", vals, function(data, status) {
        });
      });

      /* Speichern des File-Uploads */
      $('#upload-button').on('click', function() {
        var formData = new FormData(jQuery('#upload-form')[0]);
        formData.append('what', 'upload');
        formData.append('type', $('#file_upload #upload-button').attr('data-filetype'));
        var vals = get_current_vals();
        for (let key in vals) {
          formData.append(key, vals[key]);
        }
        var entry_id = $('#file_upload #upload-button').attr('data-entry_id');
        $.ajax({
          url: '/admin/waitingqueues/' + entry_id + '/ajax_update',
          method: 'POST',
          data: formData,
          processData: false,  // tell jQuery not to process the data
          contentType: false,  // tell jQuery not to set contentType
          success: function(data) {
            $('#file_upload').modal('hide');
          }
        });
      });

    };

    waitingqueues_set_event_handler();    /* Setzen Event-Handler */

    function get_current_vals() {
      var keys = ['greeting', 'bye', 'musicclass_id', 'timeout', 'announcefreq', 'caller_exit_destination', 'maxlen', 'maxtime', 'next', ];
      var strategy = $("input[type='radio'][name='waitingqueue[strategy]']:checked").val();
      var show_queueinfo = $("#waitingqueue_show_queueinfo option:selected").val();
      var result = {};
      result['waitingqueue[strategy]'] = strategy;
      result['waitingqueue[show_queueinfo]'] = show_queueinfo;
      for(var i=0; i<keys.length; i++) {
        var k1 = '#waitingqueue_' + keys[i];
        var k2 = 'waitingqueue[' + keys[i] + ']';
        result[k2] = $(k1).val();
      }
      return result;
    }

    function set_upload(title, filetype, file_type_text) {
      $('[data-toggle="tooltip"]').tooltip('hide');
      var id = url_get_id('form[action^="/admin/waitingqueues/"]');
      $('#file_upload .modal-title').html(title);
      $('#file_upload #upload-button').attr('data-filetype', filetype);
      $('#file_upload #upload-button').attr('data-entry_id', id);
      $('#file_upload #file_type').html(file_type_text);
      $('#file_upload').modal();
    }

    /* Anzeige der Buttons für Abhören und Löschen nur bei vorhandenen Ansagen */
    if ( $('#waitingqueue_greeting').val().length > 0) {
      $('button[name=greetplay]').show()
      $('button[name=greetdel]').show()
    } else {
      $('button[name=greetplay]').hide()
      $('button[name=greetdel]').hide()
    }
    if ( $('#waitingqueue_bye').val().length > 0) {
      $('button[name=byeplay]').show()
      $('button[name=byedel]').show()
    } else {
      $('button[name=byeplay]').hide()
      $('button[name=byedel]').hide()
    }
  }

  if ( $('#waitingqueue_member_edit_title').length ) {
    function check_autologout() {
      var dyn = $('#waitingqueue_dynphonesupport').prop('checked');
      var login = $('#waitingqueue_dynactivate').val();
      var logout = $('#waitingqueue_dyndeactivate').val();
      if ( dyn || ( login.length > 0 && logout.length > 0 ) ) {
        $('.show_autologout').show();
      } else {
        $('.show_autologout').hide();
      }
    }
    $('#waitingqueue_autologout').on('change', function() {
      if ( $('#waitingqueue_autologout').prop('checked') ) {
        $('.logout_time').show();
      } else {
        $('.logout_time').hide();
      }
    });
    $('#waitingqueue_autologout').change();

    $('.timepicker').clockpicker({autoclose: true, placement: 'top'});

    $('#waitingqueue_dynactivate').on('change', check_autologout);
    $('#waitingqueue_dyndeactivate').on('change', check_autologout);
    $('#waitingqueue_dynphonesupport').on('change', check_autologout);
    check_autologout();

    $('#speed_select_members').on('click', function() {
      var quick_agents = $('#waitingqueue_quick_agents').val();
      $('#speed_select_members-dialog #static_members').prop('checked', quick_agents & 2);
      $('#speed_select_members-dialog #dynamic_members').prop('checked', quick_agents & 1);
      permitted_users_show_search();
      $('#speed_select_members-dialog').modal();
    });

    $('#speed_select_members-dialog #save-button').on('click', function() {
      var value = 0;
      if ( $('#speed_select_members-dialog #static_members').prop('checked') )
        value |= 2;
      if ( $('#speed_select_members-dialog #dynamic_members').prop('checked') )
        value |= 1;
      $('#waitingqueue_quick_agents').val(value);
      $('#speed_select_members-dialog').modal('hide');
    });
  }

});
