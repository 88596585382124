import { block_enable } from '../iptam.js';

jQuery(document).ready(function() {

  if ( $('#tapi_title').length ) {
    $('#h1-enable').css('cursor', 'pointer');
    $('#h2-enable').css('cursor', 'pointer');
    block_enable(1);
    block_enable(2);
    $('#asteriskproxy_use_atxfer').on('change', function() {
      var value = $(this).prop('checked');
      if ( value ) {
        $('.atxfer').show();
      } else {
        $('.atxfer').hide();
      }
    });
    $('#asteriskproxy_use_atxfer').trigger('change');
  }

});
