var PhoneSnom_sp800 = (function(modul) {

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data("", {});
    var config = funcs.get_configuration();

  };

  function do_submit(funcs) {
    console.log("Submit");
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };

})(PhoneSnom_sp800 || {});
window.PhoneSnom_sp800 = PhoneSnom_sp800;
