var PhoneGrandstream_wp820 = (function(modul) {

  var ext_info;

  function init(pdata, funcs) {
    console.log("Telefon " + pdata.vendor + " " + pdata.model);
    funcs.set_initial_data("", {});
    var config = funcs.get_configuration();

    $('span#missed-led').on('click', function() {
      funcs.click_change_text('missed-led', 1, 'grandstream-led-texts', 'missed_led');
    });

    $('span#display').on('click', function() {
      funcs.click_change_text('display', 4, 'phone-name-texts');
    });

    $('#datetime').on('click', function() {
      funcs.click_change_text('datetime', 3, 'grandstream-datetime-texts');
    });

    if ( config['ringtones'] && config['ringtones'].length > 0 ) {
      for (var i = 0; i < 10; i++) {
        $('#rt-'+(i+1)).val(config['ringtones'][i]);
      }
    }

    if ( config['ignore_alert_info'] ) {
      $('#ignore_alert_info').prop('checked', config['ignore_alert_info']);
    }

    $('select[id^="rt-"]').on('change', function() {
      var id = parseInt(this.id.substr(3));
      funcs.set_gs_ringtone(id-1, $(this).val());
    });
  };

  function do_submit(funcs) {
    console.log("Submit");
    var ign_alert_info = $('#ignore_alert_info').prop('checked');
    funcs.update_value('ignore_alert_info', ign_alert_info);
    funcs.do_submit();
  };

  return {
    init: init,
    do_submit: do_submit
  };

})(PhoneGrandstream_wp820 || {});
window.PhoneGrandstream_wp820 = PhoneGrandstream_wp820;
